import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import GermanyIcon from '../../assets/img/icons/germany.png';
import RomaniaIcon from '../../assets/img/icons/romania.png';
import UnitedKingdomIcon from '../../assets/img/icons/united-kingdom.png';
import AppContext from '../../context/Context';

const languages = [
  {
    code: 'en',
    name: 'English',
    icon: UnitedKingdomIcon
  },
  {
    code: 'ro',
    name: 'Romanian',
    icon: RomaniaIcon
  },
  {
    code: 'de',
    name: 'German',
    icon: GermanyIcon
  }
];

export default function LanguageDropdown() {
  const { i18n } = useTranslation();
  const { language, setLanguage } = useContext(AppContext);

  const [selectedLanguage, setSelectedLanguage] = useState(
    languages.find((lang) => lang.code === language) || languages[0]
  );

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    localStorage.setItem('language', language.code);
    setLanguage(language.code);
    i18n.changeLanguage(language.code);
  };

  useEffect(() => {
    const storedLanguageCode = language || languages[0].code;
    const initialLanguage = languages.find((lang) => lang.code === storedLanguageCode);

    handleLanguageChange(initialLanguage);
  }, []);

  return (
    <UncontrolledDropdown className="mx-3">
      <DropdownToggle
        color="link"
        size="sm"
        className="text-600 btn-reveal px-0"
      >
        <LanguageItem language={selectedLanguage} />
      </DropdownToggle>
      <DropdownMenu right className="border py-2" style={{ minWidth: 80 }}>
        {languages.map((language, index) => (
          <div key={index}>
            <button
              className="dropdown-item"
              onClick={() => handleLanguageChange(language)}
            >
              <LanguageItem language={language} />
            </button>
          </div>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

const LanguageItem = ({ language }) => {
  return (
    <div className="d-flex align-items-center">
      <span title={language.name}>{language.code}</span>
      <img src={language.icon} alt={language.name} className="ml-2" />
    </div>
  );
};
